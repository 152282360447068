import { Component, OnInit, Inject, Input } from '@angular/core';
import { Cover } from '../../../shared/classes/cover';
import { Manufacturer } from '../../../shared/classes/manufacturer';
import { Product } from '../../../shared/classes/product';
import { ProductsApiService } from '../../../shared/services/APIServices/products-api.service';
import { WORK_METHOD } from '../../../shared/services/const';
import { Picture } from '../../../shared/classes/picture';
import { DomSanitizer } from '../../../../../node_modules/@angular/platform-browser';
import { CoverProductsHelper } from '../../../shared/helper/cover-products-helper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoversApiService } from '../../../shared/services/APIServices/covers-api.service';

@Component({
  selector: 'app-assignment-add-cover',
  templateUrl: './assignment-add-cover.component.html',
  styleUrls: ['./assignment-add-cover.component.scss']
})
export class AssignmentAddCoverComponent implements OnInit {
  public cover: Cover;

  public productSelected = {
    manufacturer_id: null,
    product_id: null
  };

  public work_methods = WORK_METHOD;

  public manufacturers: Manufacturer[] = [];
  public products: Product[];
  public productsToSelect: Product[] = [];

  public selected_manufacturer_name: string;
  public selected_product_name: string;

  @Input() order_id: string;
  @Input() company_id: string;
  @Input() cover_to_copy: Cover;


  constructor(public productsService: ProductsApiService, private sanitizer: DomSanitizer, public active_modal: NgbActiveModal, public cover_service: CoversApiService) { }

  ngOnInit() {
    // this.authService.jwt_timeout();
    if(this.cover_to_copy) {
      this.fill_copied_cover(this.cover_to_copy);
    }
    else {
      this.cover = new Cover();
      this.cover.status = 'in_order';
    }
    this.cover.order_id = this.order_id;
    this.cover["company_id"] = this.company_id;
  }

  cancel_new_cover() {
    this.active_modal.close();
  }

  create_new_cover() {
    this.cover_service.createCover(this.cover).subscribe(
      cover => {
        this.active_modal.close();
      },
      error => {
        console.error(error);
      }
    )
  }

  fill_copied_cover(cover) {
    this.cover = this.cover_to_copy;
    this.cover_to_copy = null;

    if(this.cover.product) {
      let product = this.cover.product;
      this.cover.product_id = product.id;
      this.productSelected.manufacturer_id = product.manufacturer_id;
      this.productSelected.product_id = product.id;
      this.selected_manufacturer_name = product.manufacturer_name;
      this.selected_product_name = product.name;

      this.fill_product_section(false);
    }

  }

  fill_product_section(reset: boolean) {
    this.productsService.getProducts(this.cover.cover_type).subscribe(
      products => {
        this.products = products;
        this.fillManufacturer(reset);
      },
      error => {
        console.error(error);
      }
    )

  }

  coverTypeChange(event) {
    let type = event.value;
    this.cover.product = new Product();
    if (type != 'Manholecover') {
      this.cover.work_method = null;
    }
    this.fill_product_section(true);
  }

  fillManufacturer(reset: boolean) {
    let coverProductsHelper = new CoverProductsHelper();
    this.manufacturers = coverProductsHelper.fillManufacturers(this.products, this.cover.work_method);

    if(reset) {
      this.selected_manufacturer_name = "";
      this.selected_product_name = "";
      this.productSelected["manufacturer_id"] = null;
      this.productSelected["product_id"] = null;  
    }
  }

  addImage(files: FileList) {
    // each file gets own filereader, because onload is async
    Array.from(files).forEach(file => {
      if (this.cover.images_before == undefined) {
        this.cover['images_before'] = [];
      }
      var picture = new Picture();
      picture.content_type = file.type;
      picture.file = file;
      picture.name = file.name;
      picture['saveUrl'] = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
      this.cover['images_before'].push(picture);
    });
  }

  change_selected_manufacturer(manufacturer: Manufacturer) {
    this.selected_manufacturer_name = manufacturer.name;
    this.productSelected["manufacturer_id"] = manufacturer.id;
    this.change_selectable_products(manufacturer.id);
  }

  change_selected_product(product: Product) {
    this.selected_product_name = product.name;
    this.productSelected["product_id"] = product.id;
    this.cover.product_id = product.id;
  }

  changeWorkMethod() {
    this.fillManufacturer(true);
  }

  change_selectable_products(manufacturer_id) {
    this.productsToSelect = [];
    let coverProductsHelper = new CoverProductsHelper();
    this.productsToSelect = coverProductsHelper.fillSelectableProducts(this.products, manufacturer_id, this.cover.work_method);
  }

  getCoordinates() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.cover.gps_lat = position.coords.latitude;
          this.cover.gps_lng = position.coords.longitude;
        },
        error => {
          console.error(error);
        }
      );
    }
  }

}
