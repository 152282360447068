import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormControl} from '@angular/forms';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service'
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ORDER_STATUS } from '../../../shared/services/const';


@Component({
  selector: 'app-assignments-sumlist',
  templateUrl: './assignments-sumlist.component.html',
  styleUrls: ['./assignments-sumlist.component.scss']
})
export class AssignmentsSumlistComponent implements OnInit {
  public customer_orders = [];
  public selected_ids = []
  public order_status = ORDER_STATUS;

  @Input() order_id;
  @Input() customer_id;


  constructor(private auth_service: AuthenticationService, private order_service: OrdersApiService, public active_modal: NgbActiveModal) {

   }

  ngOnInit() {
    this.auth_service.jwt_timeout();
    this.order_service.getCustomerOrders(this.customer_id).subscribe(
      orders => {
        this.customer_orders = orders;
        this.selected_ids.push(this.order_id);
      },
      error => {
        console.error(error);
      }
    )
  }

  change_selected_id(id) {
    if (this.order_id != id) {
      if (this.selected_ids.includes(id)) {
        let index = this.selected_ids.indexOf(id);
        this.selected_ids.splice(index, 1);
      }
      else {
        this.selected_ids.push(id);
      }
    }
  }

 cancel_collection_list(){
    this.active_modal.close();
 }

send_collection_list() {
    this.order_service.createOrderList(this.order_id, this.selected_ids).subscribe(
      result => {
        this.active_modal.close();
      },
      error => {
        console.error(error);
      }
    )
  }
}
