import { Component, OnInit } from '@angular/core';
import { ProductsApiService } from '../../../shared/services/APIServices/products-api.service';
// import { DeleteGuardComponent } from '../../../shared/components/delete-guard/delete-guard.component';
import { Product } from '../../../shared/classes/product';
import { WORK_METHOD } from '../../../shared/services/const';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Manufacturer } from '../../../shared/classes/manufacturer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { error } from 'protractor';

@Component({
  selector: 'app-manholecovers',
  templateUrl: './manholecovers.component.html',
  styleUrls: ['./manholecovers.component.scss']
})
export class ManholecoversComponent implements OnInit {
  public materials: Product[] = [];
  public work_methods = WORK_METHOD;
  public current_material: Product;
  public current_manufacturer: Manufacturer;
  public selectable_manufacturers: Manufacturer[] = [];
  public edit_mode: boolean = false;

  constructor(private products_service: ProductsApiService, private auth_service: AuthenticationService, private modal_service: NgbModal) { }

  ngOnInit() {
    this.auth_service.jwt_timeout();
    this.reload_manholecovers();
  }

  reload_manholecovers() {
    this.products_service.getProducts('Manholecover')
      .subscribe(
        materials => {
          this.materials = materials;
        },
        error => {
          console.error(error);
        }
      )
  }

  open_create_new_manufacturer_modal(new_manufacturer_modal) {
      this.current_manufacturer = new Manufacturer();
      this.modal_service.open(new_manufacturer_modal, { size: 'lg' });
  }

  open_create_new_material_modal(new_material_modal) {
    this.current_material = new Product();
    this.products_service.getManufacturers().subscribe(
      manufacturers => {
        this.selectable_manufacturers = manufacturers;
      },
      error => {
        console.error(error);
      }
    )
    this.modal_service.open(new_material_modal, { size: 'lg' });
  }

  open_edit_material_modal(edit_material_modal, product) {
    this.current_material = product;
    this.current_material.manufacturer_id = String(product.manufacturer.id);
    this.edit_mode = true;

    this.products_service.getManufacturers().subscribe(
      manufacturers => {
          this.selectable_manufacturers = manufacturers;
      },
      error => {
        console.error(error);
      }
    )
    this.modal_service.open(edit_material_modal, { size: 'lg' });
  }
  
  change_selected_manufacturer(manufacturer_id: string) {
    this.current_material.manufacturer_id = manufacturer_id;
  }

  change_type(type) {
    this.current_material.type = type;
  }

  change_work_method(work_method) {
    this.current_material.work_method = work_method;
  }

  save_material_changes(material_modal) {
    this.products_service.updateProduct(this.current_material.id, this.current_material.type, this.current_material).subscribe(
      changed_product => {
        this.current_material = null;
        this.edit_mode = false;
        material_modal.close();
        this.reload_manholecovers();
      },
      error => {
        console.error(error);
      }
    )
  }

  create_new_material(new_material_modal) {
    let type = this.current_material.type;
    delete this.current_material['type'];

    this.products_service.createProduct(type, this.current_material).subscribe(
      new_product => {
        this.current_material = null;
        new_material_modal.close();
        this.reload_manholecovers();
      },
      error => {
        console.error(error);
      }
    )
  }

  create_new_manufacturer(new_manufacturer_modal) {
    this.products_service.createManufacturer(this.current_manufacturer).subscribe(
      new_manufacturer => {
        this.current_manufacturer = null;
        new_manufacturer_modal.close();
      },
      error => {
        console.error(error);
      }
    )
  }

  delete_material(id, type, modal) {
    let delete_material_ref = this.modal_service.open(modal, { size: 'lg', centered: true });
    let self_ref = this;
    delete_material_ref.result.then(function (data) {
      if(data == 'cancel_delete') {
        delete_material_ref.close();
      }
      else if(data == 'delete') {
        self_ref.products_service.deleteProduct(id, type).subscribe(
          deleted => {
            delete_material_ref.close();
            self_ref.reload_manholecovers();
          },
          error => {
            console.error(error);
          }
        )
      }
    });
  }
}
