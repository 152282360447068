// export let API_ENDPOINT = 'http://localhost:3100';
export let API_ENDPOINT = 'https://kundenportalapi.kds-technik.de'
//export let API_ENDPOINT = 'https://kundenportaldemo.inuit.solutions'
// export let API_ENDPOINT = 'https://testapi.inuit.solutions'



export let PRIORITIES = {
    "urgent": "Dringend",
    "normal": "Normal",
    "not_urgent": "Nicht dringend"
};

export let ORDER_STATUS = {
    "created": "Angelegt",
    "in_offer": "Angebot angefragt",
    "passed": "Freigegeben",
    "in_progress": "In Arbeit",
    "done": "Fertigstellt",
    "done_invoice": "Rechnung geschrieben"
}
export let COVER_STATUS = {
    "created": "Angelegt",
    "in_order": "In Auftrag",
    "in_check": "In Prüfung",
    "done": "Fertigstellt",
    "not_possible": "Nicht Möglich"
};

export let WORK_METHOD = {
    "einwalzbar": "Einwalzbar",
    "standard": "Standard",
    "budaplan": "Konisch",
    "smart_repair": "Alte Abdeckung neu setzen",
    "asphalt_only": "Nur Asphaltdecke"
};

export let COVER_TYPES = {
    "Manholecover": "Kanaldeckel",
    "Streetcap": "Schieberkappe",
    "Hydrantcap": "Hydrantenkappe"
};

export let ASPHALT_TYPES = {
    "hot": "Heißasphalt",
    "cold": "Kaltasphalt",
    "cast_hot": "Heißverguss"
};

export let SYNC_STATUS = {
    "updated": "updated",
    "created": "created"
};

export let MILLING = {
    "1.1": "1,10 Meter",
    "1.4": "1,40 Meter",
    "0.56": "0,56 Meter",
    "9999.0": "Ziehen",
    "8888.0": "Pflaster",
};

