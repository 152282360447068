import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/first';
import { DomSanitizer } from '@angular/platform-browser';
import { Cover } from '../../../shared/classes/cover';
import { ActivatedRoute } from '@angular/router';
import { CoversApiService } from '../../../shared/services/APIServices/covers-api.service';
import { COVER_STATUS, PRIORITIES, WORK_METHOD, COVER_TYPES, ASPHALT_TYPES, API_ENDPOINT, MILLING } from '../../../shared/services/const';
import { Picture } from '../../../shared/classes/picture';
import { ProductsApiService } from '../../../shared/services/APIServices/products-api.service';
import { Product } from '../../../shared/classes/product';
import { Manufacturer } from '../../../shared/classes/manufacturer';
import { CoverProductsHelper } from '../../../shared/helper/cover-products-helper';
import { BehaviorSubject, Subject } from 'rxjs'
import { Router } from '@angular/router';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatterService } from '../../../shared/services/date-formatter.service';
import { DateHelper } from '../../../shared/helper/date-helper';

declare var google: any;

@Component({
  selector: 'app-cover-details',
  templateUrl: './cover-details.component.html',
  styleUrls: ['./cover-details.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: DateFormatterService }
  ]
})
export class CoverDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('save_before_leave_modal', null) save_before_leave_modal: ElementRef;

  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  private routeSubscription: any;
  public cover: Cover;
  public cover_status = COVER_STATUS;
  public priorities = PRIORITIES;
  public work_methods = WORK_METHOD;
  public cover_types = COVER_TYPES;
  public asphalt_types = ASPHALT_TYPES;
  public milling = MILLING;
  private cover_id: number;
  private subscription: Subscription;

  public manufacturers: Manufacturer[] = [];
  public products: Product[];
  public productsToSelect: Product[] = [];
  public status_to_select: string[] = ["in_order", "in_check", "done", "not_possible"];

  public status_options: string[] = [];
  public showWorkSection: boolean = true;

  private restoreCover: Cover;

  public other_manufacturer_id = 1;
  public other_product_id = 1;

  public edit_mode = false;
  public selected_manufacturer_name: string;
  public selected_product_name: string;
  public selected_work_date;
  public selected_work_time;

  public inital_carousel_index = 0;

  constructor(public authService: AuthenticationService, private sanitizer: DomSanitizer, private route: ActivatedRoute, private coversService: CoversApiService, private productsService: ProductsApiService, private router: Router, private modal_service: NgbModal) {

  }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.routeSubscription = this.route.params.subscribe(
      params => {
        this.cover_id = +params['id']; // (+) converts string 'id' to a number
        this.reloadCover();
      });
  }

  reloadCover() {
    this.coversService.getCoverWithID(this.cover_id).subscribe(
      cover => {
        this.cover = cover;

        let date_helper = new DateHelper();
        this.selected_work_date = date_helper.create_jsons_from_date(this.cover.work_date);
        this.selected_work_time = date_helper.create_jsons_from_time(this.cover.work_date);

        this.cover.manufacturer_id = cover.product.manufacturer_id;
        this.cover.product_id = cover.product.id;
        this.populateMaterialList();
        this.setImageUrls();
      },
      error => {
        console.error(error);
      }
    )
  }

  setImageUrls() {
    if (this.cover.images_before) {
      for (let image of this.cover.images_before) {
        image.url_small = API_ENDPOINT + '/' + image.url_small;
        image.url = API_ENDPOINT + '/' + image.url;
      }
    }
    if (this.cover.images_after) {
      for (let image of this.cover.images_after) {
        image.url_small = API_ENDPOINT + '/' + image.url_small;
        image.url = API_ENDPOINT + '/' + image.url;
      }
    }

  }

  edit_cover() {
    this.restoreCover = Cover.copyObject(this.cover);
    this.edit_mode = true;

    this.selected_manufacturer_name = this.cover.product.manufacturer_name;
    this.selected_product_name = this.cover.product.name;

    if (this.cover.work_method) {
      this.populateMaterialList();
    }

    if (this.cover.status == 'in_order') {
      this.status_options.push('in_order');
      this.status_options.push('in_check');
      this.status_options.push('done');
    }
    else if (this.cover.status == 'in_check') {
      this.status_options.push('done')
    }
  }

  delete_cover() {
    this.coversService.deleteCoverWithID(this.cover.id).subscribe((data) => {
      this.end_edit();
      this.navigate_to_order();
    });
  }

  reset_cover() {
    this.coversService.resetCoverWithID(this.cover.id).subscribe((data) => {
      this.end_edit()
      this.navigate_to_order();
    });
  }

  navigate_to_order() {
    this.router.navigate(['auftraege', this.cover.order_id]);
  }


  save_changes(reload: boolean) {
    let date_helper = new DateHelper();
    this.cover.work_date = date_helper.create_date_from_json_date_and_time(this.selected_work_date, this.selected_work_time).toString();

    let tempcover = this.cover
    let tempcover_product = this.cover.product
    tempcover.product = tempcover_product
    this.coversService.updateCoverWithID(this.cover.id, tempcover).subscribe(
      cover => {
        this.cover = cover;
        this.restoreCover = null;
        this.end_edit();
        this.setImageUrls();
        if (reload) {
          this.reloadCover();
        }
      },
      error => {
        console.error(error);
      }
    )
  }

  end_edit() {
    this.edit_mode = false;
  }

  goMain() {
    this.router.navigate(['auftraege']).then(error => { console.log(error) })
  }

  cancelCoverEdit() {
    this.cover = Cover.copyObject(this.restoreCover);
    this.restoreCover = null;
    this.end_edit();
  }

  canDeactivate() {
    if (this.edit_mode) {
      this.modal_service.open(this.save_before_leave_modal, { size: 'lg' });
      return this.navigateAwaySelection$;
    }
    else {
      return true;
    }
}

select_leave_options(modal, save_cover: boolean, leave: boolean) {
  if(save_cover) {
    this.save_changes(false);
  }
  modal.close();
  this.navigateAwaySelection$.next(leave);
}

  open_map(map_modal) {
    this.modal_service.open(map_modal, { size: 'lg' });

    var mapProp = {
      center: new google.maps.LatLng(Number(this.cover.gps_lat), Number(this.cover.gps_lng)),
      zoom: 17,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    var map = new google.maps.Map(document.getElementById("coverMap"), mapProp);
    var marker = new google.maps.Marker({
      position: { lat: Number(this.cover.gps_lat), lng: Number(this.cover.gps_lng) },
      map: map,
      title: 'Abdeckung'
    });
  }

  addImage(type: string, files: FileList) {
    // each file gets own filereader, because onload is async
    Array.from(files).forEach(file => {
      var picture = new Picture();
      picture.content_type = file.type;
      picture.file = file;
      picture.name = file.name;
      picture['saveUrl'] = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));

      if (type == 'before') {
        if (this.cover.images_before == undefined) {
          this.cover.images_before = [];
        }
        this.cover.images_before.push(picture);
      }
      else if (type == 'after') {
        if (this.cover.images_after == undefined) {
          this.cover.images_after = [];
        }
        this.cover.images_after.push(picture);
      }
    });
  }
  

  delete_image(id: number) {
    this.coversService.deleteImage(id).subscribe(
      deleted => {
        this.reloadCover();
      },
      error => {
        console.error(error);
      }
    )
  }

  open_image_delete_modal(image_id, image_delete_modal) {
    let ref = this.modal_service.open(image_delete_modal, { size: 'lg' });
    let self_ref = this;
    ref.result.then(function (data) {
      if (data == 'delete') {
        self_ref.delete_image(image_id);
        image_delete_modal.close();
      }
    }, function (error) {
      console.error(error);
    })
  }

  open_delete_cover_modal(delete_cover_modal) {
    let delete_cover_modal_ref = this.modal_service.open(delete_cover_modal, { size: 'lg' });
    let self_ref = this;
    delete_cover_modal_ref.result.then(function (data) {
      if (data == 'delete') {
        self_ref.delete_cover();
        delete_cover_modal_ref.close();
      }
    }, function (error) {
      console.error(error);
    })
  }

  open_reset_cover_modal(reset_cover_modal) {
    let reset_cover_modal_ref = this.modal_service.open(reset_cover_modal, { size: 'lg' });
    let self_ref = this;
    reset_cover_modal_ref.result.then(function (data) {
      if (data == 'delete') {
        self_ref.reset_cover();
        reset_cover_modal_ref.close();
      }
    }, function (error) {
      console.error(error);
    })
  }

  open_image_carousel(index: number, image_carousel_modal) {
    this.inital_carousel_index = index;
    this.modal_service.open(image_carousel_modal, { size: 'lg' });
  }

  ngOnDestroy() {
    this.cancelCoverEdit();
  }

  populateMaterialList() {
    this.productsService.getProducts(this.cover.cover_type).subscribe(
      products => {
        this.products = products;
        this.fill_manufacturer();
        if (this.cover.product) {
          this.change_selectable_products(this.cover.product.manufacturer_id);
        }
      },
      error => {
        console.error(error);
      }
    )
  }

  change_cover_type(event) {
    this.selected_manufacturer_name = "";
    this.selected_product_name = "";
    let type = event.value;
    this.cover.product = new Product();
    if (type != 'Manholecover') {
      this.cover.work_method = null;
    }
    this.productsService.getProducts(this.cover.cover_type).subscribe(
      products => {
        this.products = products;
        this.fill_manufacturer();
      },
      error => {
        console.error(error);
      }
    )
  }

  change_selected_manufacturer(manufacturer: Manufacturer) {
    this.selected_manufacturer_name = manufacturer.name;
    this.selected_product_name = "";
    this.change_selectable_products(manufacturer.id);
  }

  change_selected_status(status: string) {
    this.cover.status = status;
  }

  change_selected_product(product: Product) {
    this.selected_product_name = product.name;
    this.cover.product_id = product.id;
  }

  fill_manufacturer() {
    this.manufacturers = [];
    let coverProductsHelper = new CoverProductsHelper();
    if (this.cover.work_method == 'smart_repair' || this.cover.work_method == 'asphalt_only') {
      this.manufacturers = coverProductsHelper.fillManufacturers(this.products);
    }
    else {
      this.manufacturers = coverProductsHelper.fillManufacturers(this.products, this.cover.work_method);
    }
  }

  change_work_method(event) {
    this.cover.product = new Product();
    this.selected_manufacturer_name = "";
    this.selected_product_name = "";
    this.fill_manufacturer();
  }

  change_selectable_products(manufacturer_id) {
    this.productsToSelect = [];
    let coverProductsHelper = new CoverProductsHelper();
    this.productsToSelect = coverProductsHelper.fillSelectableProducts(this.products, manufacturer_id, this.cover.work_method);
  }


  change_status(status) {
    this.cover.status = status

    this.coversService.updateCoverWithID(this.cover.id, this.cover).subscribe(
      cover => {
        this.cover = cover;
        this.reloadCover();
      },
      error => {
        console.error(error);
      }
    )
  }
}

