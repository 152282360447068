import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Product } from '../../classes/product';
import { API_ENDPOINT } from '../const';
import { Manufacturer } from '../../classes/manufacturer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductsApiService {
  private manufacturerStorageName = 'manufacturers';

  constructor(private http: HttpService) { }

  getProducts(type: String) {
    let typeUrl = this.getTypeUrl(type);
    return this.http.get(API_ENDPOINT + '/' + typeUrl + '/');
  }

  updateProduct(id: string, type: string, product: Product) {
    let typeUrl = this.getTypeUrl(type);

    return this.http.put(API_ENDPOINT + '/' + typeUrl + '/' + id, product).map(
      product => {
        return product;
      }
    );
  }

  createProduct(type: string, product: Product) {
    let typeUrl = this.getTypeUrl(type);
    return this.http.post(API_ENDPOINT + '/' + typeUrl, product).map(
      product => {
        return product;
      }
    )
  }

  deleteProduct(id: string, type: string, ) {
    let typeUrl = this.getTypeUrl(type);

    return this.http.delete(API_ENDPOINT + '/' + typeUrl + '/' + id).map(
      response => {
        return response;
      },
    );
  }

  getTypeUrl(type: String) {
    var typeUrl = "";
    switch (type) {
      case 'Manholecover':
        typeUrl = 'manholecovers';
        break;
      case 'Streetcap':
        typeUrl = 'streetcaps';
        break;
      case 'Hydrantcap':
        typeUrl = 'hydrantcaps';
        break;
    }
    return typeUrl;
  }

  getManufacturers() {
    return this.http.get(API_ENDPOINT + '/manufacturers/').map(
      results => {
        return results;
      },
    );
  }

  createManufacturer(manufacturer: Manufacturer) {
    return this.http.post(API_ENDPOINT + '/manufacturers', manufacturer).map(
      manufacturer => {
        return manufacturer;
      },
    );
  }
}
