import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersComponent } from './customers/customers.component';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { CustomersDetailsComponent } from './customers-details/customers-details.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    NgbModule, 
  ],
  declarations: [
    CustomersComponent,
    CustomersDetailsComponent,
  ]})
export class CustomersModule { }
