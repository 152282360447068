import { User } from "./user";
import { Vehicle } from "./vehicle";

export class Team {
    public id: number;
    public name: string;
    public employees: User[];
    public phone: string;
    public vehicle: Vehicle;
    public employee_ids: any[];
    public vehicle_id: number;
}
