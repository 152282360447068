import { Component, OnInit, ViewChild, ViewChildren, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Router, NavigationStart } from '@angular/router';
import { CoversApiService } from '../../../shared/services/APIServices/covers-api.service';
import { Cover } from '../../../shared/classes/cover';
import { Picture } from '../../../shared/classes/picture';



@Component({
  selector: 'app-covers',
  templateUrl: './covers.component.html',
  styleUrls: ['./covers.component.scss']
})
export class CoversComponent {

  constructor() {
  }
}
