import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoversRoutingModule } from './covers-routing.module';
import { CoversComponent } from './covers/covers.component';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { CoverDetailsComponent } from './cover-details/cover-details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    CoversRoutingModule,
    SharedModule,
    NgbModule
  ],
  declarations: [
    CoversComponent,
    CoverDetailsComponent,
  ]
})
export class CoversModule { }
