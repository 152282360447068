import { Component, OnInit, OnDestroy, PipeTransform } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { Assignment } from '../../../shared/classes/assignment';
import { Subscription, Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-assignments-pending',
  templateUrl: './assignments-pending.component.html',
  styleUrls: ['./assignments-pending.component.scss']
})
export class AssignmentsPendingComponent implements OnInit {
  public assignments: Assignment[];

  constructor(private authService: AuthenticationService, private ordersService: OrdersApiService) {
 
  }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.reloadOrders();
  }

  reloadOrders() {
      this.ordersService.getOrderWithStatus('passed').subscribe(
        orders => {
          this.assignments = orders;
        },
        error => {
          console.error(error);
        }
      )
  }

}
