import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-forbidden-error',
  templateUrl: './forbidden-error.component.html',
  styleUrls: ['./forbidden-error.component.scss']
})
export class ForbiddenErrorComponent implements OnInit {
  public user_is_admin = false;

  constructor(private auth_service: AuthenticationService) { }

  ngOnInit() {
    if(this.auth_service.isAdmin()) {
      this.user_is_admin = true;
    }
  }

}
