import { Component, OnInit, PipeTransform } from '@angular/core';
import { CustomersApiService } from '../../../shared/services/APIServices/customers-api.service';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { Customer } from '../../../shared/classes/customer';
import { Assignment } from '../../../shared/classes/assignment';
import { User } from '../../../shared/classes/user';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { startWith, map, count } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserApiService } from '../../../shared/services/APIServices/user-api.service';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  providers: [DecimalPipe]
})
export class CustomersComponent implements OnInit {
  customers$: Observable<Customer[]>
  filter = new FormControl('');
  private filter_customers: Customer[] = [];
  private new_customer;

  constructor(public customers_service: CustomersApiService, private orderService: OrdersApiService, private router: Router, private authService: AuthenticationService, pipe: DecimalPipe, private modal_service: NgbModal, private users_service: UserApiService) {
    this.customers_service.getCustomers()
      .subscribe(
        customers => {
          this.filter_customers = customers;
          this.customers$ = this.filter.valueChanges.pipe(
            startWith(''),
            map(text => this.search(text, pipe))
          );
        },
        error => {
          console.error(error);
        }
      )
  }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.reload_customers();
  }

  reload_customers() {
    // this.customers$ = this.customers_service.getCustomers();
    this.customers_service.getCustomers()
      .subscribe(
        customers => {
          console.log(customers[1]);
          this.filter_customers = customers;
        },
        error => {
          console.error(error);
        }
      )

    // this.customers_service.getCustomers()
    //   .subscribe(
    //     customers => {
    //       this.filter_customers = customers;
    //       this.customers$ = this.filter.valueChanges.pipe(
    //         startWith(''),
    //         map(text => this.search(text, pipe))
    //       );
    //     },
    //     error => {
    //       console.error(error);
    //     }
    //   )
  }

  open_new_order_for_customer_modal(customer_id, new_order_modal) {
    let new_order_modal_ref = this.modal_service.open(new_order_modal, { size: 'lg', centered: true });
    let self_ref = this;
    new_order_modal_ref.result.then(function (data) {
      if (data == 'cancel_create_order') {
        new_order_modal_ref.close();
      }
      else if (data == 'create_order') {
        self_ref.add_order_for_customer(customer_id);
      }
    });
  }

  // TODO Customer ID to delete the right customer
  add_order_for_customer(id: number) {
    var customerOrder = new Assignment;
    var today = new Date().toISOString().slice(0, 10);

    customerOrder.status = 'in_progress';
    customerOrder.company_id = id;
    customerOrder.order_date = today;
    customerOrder.passing_date = today;
    this.orderService.createOrder(customerOrder).subscribe(res => {
      this.router.navigate(['/auftraege', res.id])
    });
  }


  search(text: string, pipe: PipeTransform): Customer[] {
    return this.filter_customers.filter(customer => {
      const term = text.toLowerCase();
      var customer_contains_term;
      if(customer.main_contact) {
        customer_contains_term = customer.main_contact.name.toLowerCase().includes(term);
      }

      return customer.name.toLowerCase().includes(term)
        || customer_contains_term;
    });
  }

  create_new_customer() {
    if(this.new_customer != null) {
      var temp_customer = {
        'name': this.new_customer.name,
        'street': this.new_customer.street,
        'zip_code': this.new_customer.zip_code,
        'city': this.new_customer.city,
      }

      this.customers_service.create_customer(temp_customer).subscribe(
        customer => {
          if(this.new_customer.main_contact_attributes) {
            var new_user = {
              "name": this.new_customer.main_contact_attributes.name,
              "email": this.new_customer.main_contact_attributes.email,
              "phone": this.new_customer.main_contact_attributes.phone,
              "mobile": this.new_customer.main_contact_attributes.mobile,
              "imported": true,
              "company_id": customer.id, 
              "type": "Customer",
              "password_digest": "imported",
              "access_to_portal": false
            };
            this.users_service.create_new_user(new_user).subscribe(
              user => {
                this.new_customer = null;
                this.router.navigate(['/kunden', customer.id])
              },
              error => {
                console.error(error);
              }
            );          
          }
          else {
            this.new_customer = null;
            this.router.navigate(['/kunden', customer.id])
          }
        },
        error => {
          console.error(error);
        }
      );
    }
  }


  open_new_customer_modal(new_customer_modal) {
    this.new_customer = {
      'name': "",
      'street': "",
      'zip_code': "",
      'city': "",
      'main_contact_attributes': {
        'name': "",
        'phone': "",
        'mobile': "",
        'email': ""
      }
    };

    let new_customer_modal_ref = this.modal_service.open(new_customer_modal, { size: 'lg', centered: true });
    let self_ref = this;

    new_customer_modal_ref.result.then(function (data) {
      if (data == 'cancel_create_customer') {
          new_customer_modal_ref.close();
      }
      else if (data == 'create_customer') {
        self_ref.create_new_customer();
      }
    });
  }


}

