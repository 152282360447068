import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT } from '../const';
import { Vehicle } from '../../classes/vehicle';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehiclesApiService {
  private storageName = 'vehicles';

  constructor(private http: HttpService) { }

  getVehicles() {
      return this.http.get(API_ENDPOINT + '/vehicles').map(
        vehicles => {
          return vehicles;
        }
      );
  }

  getVehicleWithID(id: number) {
      return this.http.get(API_ENDPOINT + '/vehicles/' + id).map(
        result => {
          return result;
        }
      );
  }

  createVehicle(vehicle: Vehicle) {
      return this.http.post(API_ENDPOINT + '/vehicles', vehicle).map(
        asphaltwork => {
          return asphaltwork;
        },
      );
  }

  updateVehicleWithID(id: number, vehicle: Vehicle) {
      return this.http.put(API_ENDPOINT + '/vehicles/' + id, vehicle).map(
        asphaltwork => {
          return asphaltwork;
        }
      );
  }

  deleteVehicleWithID(id: number) {
      return this.http.delete(API_ENDPOINT + '/vehicles/' + id).map(
        response => {
          return response;
        },
      );
  }
}
