import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public hasRedirect: boolean;
  public username: string = "";
  public password: string = "";

  // public error: boolean;
  public errormessage: string = null;
  public messages = [];

  constructor(private authService: AuthenticationService) {
    if (this.authService.redirectUrl != '') {
      this.hasRedirect = true;
    }
  }

  ngOnInit() {
  }

  login() {
    this.errormessage = null;
    this.authService.login(this.username, this.password)
      .subscribe(
        loggedIn => {
          // TODO check for admin!!!
          this.username = '';
          this.password = '';
          // redirect to redirect url?
        },
        err => {
          this.username = '';
          this.password = '';

          if (err.status == 403 || err.status == 401) {
            this.errormessage = 'Benutzername oder Passwort falsch';
          }
          else {
            console.error(err);
          }
        });
  }


}
