import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT, SYNC_STATUS } from '../const';
import { Observable } from 'rxjs/Observable';
import { Cover } from '../../classes/cover';
import { ProductsApiService } from './products-api.service';
import { PDF } from '../../classes/pdf';

@Injectable()
export class CoversApiService {
  private storageName = 'covers';

  constructor(private http: HttpService, private productsApiService: ProductsApiService) { }

  getCoversOpen() {
    let params = {
      status: 'created'
    }

      return this.http.get(API_ENDPOINT + '/covers', params).map(
        results => {
          return results;
        },
      );
  }

  getCoversInCheck() {
    let params = {
      status: 'in_check'
    }
      return this.http.get(API_ENDPOINT + '/covers', params).map(
        results => {
          return results;
        },
      );
  }

  get_covers_with_status(status: string) {
    
  }

  getCoversForOrderWithID(order_id: number) {
    let params = {
      order_id: order_id
    }
      return this.http.get(API_ENDPOINT + '/covers', params).map(
        results => {
          return results;
        },
      );
  }

  getCoverWithID(id: number) {
      return this.http.get(API_ENDPOINT + '/covers/' + id).map(
        result => {
          return result;
        }
      );
  }

  createCover(cover: Cover) {
      let upload_cover = this.convertCoverToFormdata(cover);
      return this.http.post(API_ENDPOINT + '/covers', upload_cover).map(
        result => {
          return result;
        }
      );
  }

  updateCoverWithID(id: number, cover: Cover) {
      let upload_cover = this.convertCoverToFormdata(cover);
      return this.http.put(API_ENDPOINT + '/covers/' + id, upload_cover).map(
        result => {
          return result;
        }
      );
  }

  deleteCoverWithID(id: number) {
      return this.http.delete(API_ENDPOINT + '/covers/' + id).map(
        response => {
          return response;
        },
      );
  }

  resetCoverWithID(id: number) {
      return this.http.delete(API_ENDPOINT + '/reset_cover/' + id).map(
        result => {
          return result;
        }
      );
  }

  deleteMultipleCovers(ids: number[]) {
    let params = {
      'ids[]': ids
    }
      return this.http.deleteMultiple(API_ENDPOINT + '/covers', params ).map(
        response => {
          response.forEach(id => {
            return response;
          });
        }
      )
  }

  addCoversToOrder(order_id: number, ids: number[]) {
    let params = {
      'ids[]': ids
    }
      return this.http.putMultiple(API_ENDPOINT + '/covers', { order_id: order_id }, params).map(
        results => {
          return results;
        }
      );
  }

  deleteImage(id: number){
      return this.http.delete(API_ENDPOINT + '/image/' + id).map(
        response => {
              return response;
        }
      );
  }

  sendWorkMail(id: number){
      return this.http.post(API_ENDPOINT + '/work/'+ id, id).map(
        result => {
          return result;
        }
      );
    }

  sendFinishedMail(id: number) {
    return this.http.post(API_ENDPOINT + '/finishedmail/' + id, id).map(
      result => {
        return result;
      }
    )
  }


  addFile(file: File, id: number, type: string){
      var pdfdata: FormData = new FormData();
      pdfdata.append("file", file)
      pdfdata.append("id", id.toString())
      pdfdata.append("type", type)
      return this.http.post(API_ENDPOINT + '/file/', pdfdata).map(
        response => {
              return response;
        }
      );
  }


  deleteFile(id: number){
      return this.http.delete(API_ENDPOINT + '/file/'+ id).map(
        response => {
              return response;
        }
      );
  }

  private convertCoverToFormdata(cover: Cover) {
    var formdata: FormData = new FormData();
    let unpermittedKeys = ['id', 'product', 'image'];
    let boolean_keys = ['cover_by_customer', 'delivered_dusttrap', 'desired_dusttrap'];

    for (let key in cover) {
      if (cover[key]) {
        if (unpermittedKeys.includes(key)) {
          delete cover[key];
        }
        else if (key == 'images_before') {
          for (let picture of cover['images_before']) {
            if (picture.file) {
              formdata.append('images_before[]', picture.file);
            }
          }
        }
        else if (key == 'images_after') {
          for (let picture of cover['images_after']) {
            if (picture.file) {
              formdata.append('images_after[]', picture.file);
            }
          }
        }
        else {
          let value = cover[key];
          if(value == false) {
            value = "0";
          }
          if(value == true) {
            value = "1";
          }
          formdata.append(key, cover[key].toString());
        }
      }
      else if (boolean_keys.includes(key)) {
        var value;
        if(cover[key]){
          value = 1;
        }
        else{
          value = 0;
        }
        formdata.append(key, value.toString());
      }
  }
    return formdata;
  }

  private createID() {
    return new Date().getTime()
  }
}
