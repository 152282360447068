import { Component, OnInit } from '@angular/core';
// import { DeleteGuardComponent } from '../../../shared/components/delete-guard/delete-guard.component';
import { Vehicle } from '../../../shared/classes/vehicle';
import { VehiclesApiService } from '../../../shared/services/APIServices/vehicles-api.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatterService } from '../../../shared/services/date-formatter.service';
import { DateHelper } from '../../../shared/helper/date-helper';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: DateFormatterService }
  ]
})
export class VehicleComponent implements OnInit {

  public vehicles: Vehicle[] = [];
  private current_vehicle = null;
  private selected_inspection_date;

  constructor(private vehicles_service: VehiclesApiService, private auth_service: AuthenticationService, private modal_service: NgbModal) { }

  ngOnInit() {
    this.auth_service.jwt_timeout();
    this.reload_vehicles();
  }

  reload_vehicles() {
    this.vehicles_service.getVehicles().subscribe(
      vehicles => {
        this.vehicles = vehicles;
      },
      error => {
        console.error(error);
      }
    )
  }

  open_create_new_vehicle_modal(new_vehicle_modal) {
    this.current_vehicle = new Vehicle();
    this.selected_inspection_date = null;
    this.modal_service.open(new_vehicle_modal, { size: 'lg', centered: true });
  }

  create_vehicle(new_vehicle_modal) {
    if(this.selected_inspection_date) {
      let date_helper = new DateHelper();
      this.current_vehicle.inspection_date = date_helper.create_date_from_json(this.selected_inspection_date).toString();  
    }
    this.vehicles_service.createVehicle(this.current_vehicle).subscribe(
      new_vehicle_result => {
        this.current_vehicle = null;
        new_vehicle_modal.close();
        this.reload_vehicles();
      },
      error => {
        console.error(error);
      }
    )
  }

  edit_vehicle(id: number, edit_vehicle_modal) {
    let temp_vehicle = this.get_vehicle_from_array_with_id(id);
    this.current_vehicle = Object.assign({}, temp_vehicle); // "clone" object (but not sub-objects)

    if (this.current_vehicle.inspection_date) {
      let date_helper = new DateHelper();
      this.selected_inspection_date = date_helper.create_jsons_from_date(this.current_vehicle.inspection_date);
    }
    
    let vehicle_edit_modal_ref = this.modal_service.open(edit_vehicle_modal, { size: 'lg', centered: true });
    let self_ref = this;
    vehicle_edit_modal_ref.result.then(function (data) {
      if (data == 'cancel_edit') {
        vehicle_edit_modal_ref.close();
      }
      else if (data == 'save_edit') {
        if(self_ref.selected_inspection_date) {
          let date_helper = new DateHelper();
          self_ref.current_vehicle.inspection_date = date_helper.create_date_from_json(self_ref.selected_inspection_date).toString();  
        }
    
        self_ref.vehicles_service.updateVehicleWithID(id, self_ref.current_vehicle).subscribe(
          edit_vehicle_result => {
            vehicle_edit_modal_ref.close();
            self_ref.reload_vehicles();
          },
          error => {
            console.error(error);
          }
        )
      }
    })
  }

  delete_vehicle(id, delete_vehicle_modal) {
    let delete_vehicle_modal_ref = this.modal_service.open(delete_vehicle_modal, { size: 'lg', centered: true });
    let self_ref = this;
    delete_vehicle_modal_ref.result.then(function (data) {
      if(data == 'cancel_delete_vehicle') {
        delete_vehicle_modal_ref.close();
      }
      else if(data == 'delete_vehicle') {
        self_ref.vehicles_service.deleteVehicleWithID(id).subscribe(
          deleted => {
            delete_vehicle_modal_ref.close();
            self_ref.reload_vehicles();
          },
          error => {
            console.error(error);
          }
        )
      }
    });  
  }

  get_vehicle_from_array_with_id(id: number) {
    return this.vehicles.find(vehicle => vehicle.id === id);
  }
}
