import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { API_ENDPOINT } from './const';
//import { Response } from "@angular/http";
import { JwtHelper } from '../helper/jwt-helper';


@Injectable()
export class AuthenticationService {

  public token: string;
  public tokenKey: string = 'userToken';
  public isAuthenticated: boolean = false;
  public user;

  public redirectUrl = '';


  constructor(private http: HttpService, private router: Router) {
    var currentToken = localStorage.getItem(this.tokenKey);

    if (currentToken && this.tokenValid(currentToken)) {
      this.setAuthenticationInfos(true, currentToken);
    }
    else {
      this.setAuthenticationInfos(false);
    }
  }

  setAuthenticationInfos(isAuthenticated: boolean, token?: string) {
    this.isAuthenticated = isAuthenticated;

    if (isAuthenticated) {
      this.token = token;
      this.http.setDefaultHeader(isAuthenticated, token);
      var jwtHelper = new JwtHelper();
      this.user = jwtHelper.decodeToken(token);
    }
    else {
      this.token = null;
      this.user = null;
      localStorage.removeItem(this.tokenKey);
      this.http.setDefaultHeader(isAuthenticated);
    }
  }

  login(email: string, password: string) {
    return this.http.post(API_ENDPOINT + '/auth/login', { email: email, password: password })
      .map(response => {
        // login successful if there's a jwt token in the response
        let token = response.auth_token;
        if (token) {

          localStorage.setItem(this.tokenKey, token);
          this.setAuthenticationInfos(true, token);

          //if redirect url  => redirect
          if (this.redirectUrl.length > 0) {
            let url = this.redirectUrl;
            this.redirectUrl = '';
            this.router.navigate([url]);
          }
          else {
            this.router.navigate(['/']);
          }
          return true;
        }
        else {
          // return false to indicate failed login
          return false;
        }
      })
  }

  logout() {
    localStorage.removeItem(this.tokenKey);
    this.setAuthenticationInfos(false);
    this.router.navigate(['/login']);
  }

  tokenValid(token: string): boolean {
    var jwtHelper = new JwtHelper();
    var tempUser = jwtHelper.decodeToken(token);

    // * 1000 because of the ruby generated date
    var expDate = new Date(tempUser.exp * 1000);

    if (expDate.getTime() > Date.now()) {
      return true;
    }
    else {
      return false;
    }
  }


  public isAdmin(): boolean {
    if (this.user.type == 'Admin') {
      return true;
    }
    return false
  }

  public jwt_timeout(){
    let token = localStorage.getItem('userToken');
    let is_valid = this.tokenValid(token);
    if (is_valid == false) {
      localStorage.removeItem('userToken');
      this.router.navigate(['/login']);
    }
  }
}

