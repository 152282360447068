import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Assignment } from '../../../shared/classes/assignment';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';

@Component({
  selector: 'app-assignments-offer',
  templateUrl: './assignments-offer.component.html',
  styleUrls: ['./assignments-offer.component.scss']
})
export class AssignmentsOfferComponent implements OnInit {
  public assignments: Assignment[];

  constructor(private authService: AuthenticationService, private ordersService: OrdersApiService) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.reloadOrders();
  }

  reloadOrders() {
    this.ordersService.getOrderWithStatus('in_offer').subscribe(
      orders => {
        this.assignments = orders;
      },
      error => {
        console.error(error);
      }
    )
  }

}
