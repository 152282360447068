import { User } from "./user";
import { Assignment } from "./assignment";

export class Customer {
    public id: number;
    public name: string;
    public street: string;
    public zip_code: string;
    public city: string;
    public main_contact: User;
    public active_orders: number;
    public comments: Text;

    public orders_passed: Assignment[];
    public orders_in_progress: Assignment[];
    public orders_in_offer: Assignment[];
    public orders_done: Assignment[];
    public orders_done_invoice: Assignment[];

    static copyObject(orginial: Customer): Customer {
        var copiedCustomer: Customer = new Customer();

        for(let property in orginial) {
            copiedCustomer[property] = orginial[property];
        }
        return copiedCustomer;
    }}
