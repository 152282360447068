import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT } from '../const';
import { Customer } from '../../classes/customer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomersApiService {
  private storageName = 'customers';

  constructor(private http: HttpService) { }

  getCustomers() {
      return this.http.get(API_ENDPOINT + '/companies/').map(
        results => {
          return results;
        },
      );
  }

  getCustomerWithID(id: number) {
      return this.http.get(API_ENDPOINT + '/companies/' + id).map(
        result => {
          // TODO check with cover o.ä
          return result;
        }
      );
  }

  create_customer(customer: any) {
    return this.http.post(API_ENDPOINT + '/companies', customer).map(
      result => {
        return result;
      },
    );
  }

  updateCustomerWithID(id: number, customer: Customer) {
      return this.http.put(API_ENDPOINT + '/companies/' + id,  this.updateCustomerJson(customer)).map(
        result => {
          return result;
        }
      );
  }

  resetPassword(id: number) {
      return this.http.post(API_ENDPOINT + '/reset_password', this.createPasswordResetJson(id)).map(
        result => {
          return result;
        },
      );
  }

  sendPortalAccess(id: number) {
      return this.http.post(API_ENDPOINT + '/new_password', this.createPasswordResetJson(id)).map(
        result => {
          return result;
        },
      );
  }

  createCustomerJson(customer:Customer) {
    return ({
      name: customer.name,
      street: customer.street,
      zip_code: customer.zip_code,
      city: customer.city,
      comments: customer.comments,
      main_contact_attributes: {
        name: customer.main_contact.name,
        email: customer.main_contact.email,
        phone: customer.main_contact.phone,
        mobile: customer.main_contact.mobile,
        password: this.createPassword()
      }
    })
  }

  updateCustomerJson(customer:Customer) {
    return ({
      name: customer.name,
      street: customer.street,
      zip_code: customer.zip_code,
      city: customer.city,
      comments: customer.comments,
      main_contact_attributes: {
        name: customer.main_contact.name,
        email: customer.main_contact.email,
        phone: customer.main_contact.phone,
        mobile: customer.main_contact.mobile,
      }
    })
  }

  createPasswordResetJson(id: number){
    var password = this.createPassword();
    console.log(password);
    return ( {
      id: id,
      password: password
    })
  }

  createPassword(){
    let length = 10;
    let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = '';

    for (var i = 0, factor = charset.length; i < length; ++i){
      password += charset.charAt(Math.floor(Math.random() * factor));
    }
    return password
  }
}
