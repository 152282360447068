import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialsComponent } from './materials/materials.component';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { ManholecoversComponent } from './manholecovers/manholecovers.component';
import { StreetcapsComponent } from './streetcaps/streetcaps.component';
import { HydrantcapsComponent } from './hydrantcaps/hydrantcaps.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    MaterialsComponent, 
    ManholecoversComponent, 
    StreetcapsComponent, 
    HydrantcapsComponent
  ]
})
export class MaterialsModule { }
