import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT } from '../const';
import { User } from '../../classes/user';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeesApiService {
  private storageName = 'employees';

  constructor(private http: HttpService) { }

  getEmployees() {
      return this.http.get(API_ENDPOINT + '/employees').map(
        results => {
          return results;
        },
      );
  }

  getEmployeeWithID(id: number) {
      return this.http.get(API_ENDPOINT + '/employees/' + id).map(
        result => {
          return result;
        }
      );
  }

  createEmployee(employee: User) {
      return this.http.post(API_ENDPOINT + '/employees', employee).map(
        result => {
          return result;
        },
      );
  }

  updateEmployeeWithID(id: number, employee: User) {
      return this.http.put(API_ENDPOINT + '/employees/' + id, employee).map(
        result => {
          return result;
        }
      );
  }

  updateMultipleEmployeesWithTeamID(team_id: number, employee_ids: number[]) {
    var params = {
      team_id: team_id
    };

    var routeparams = '?';

    for(var id of employee_ids) {
      routeparams += 'ids[]=' + id + '&'; 
    }

      return this.http.put(API_ENDPOINT + '/employees/' + routeparams, params).map(
        result => {
          return result;
        }
      );
  }

  deleteEmployeeWithID(id: number) {
      return this.http.delete(API_ENDPOINT + '/employees/' + id).map(
        response => {
          return response;
        },
      );
  }

}
