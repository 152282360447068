import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthGuardService } from '../../shared/services/auth-guard.service';
import { AssignmentsComponent } from '../assignments/assignments/assignments.component';
import { LoginComponent } from '../../components/login/login.component';
import { CoversComponent } from '../covers/covers/covers.component';
import { AssignmentsPendingComponent } from '../assignments/assignments-pending/assignments-pending.component';
import { AssignmentsProgressComponent } from '../assignments/assignments-progress/assignments-progress.component';
import { AssignmentsCompletedComponent } from '../assignments/assignments-completed/assignments-completed.component';
import { AssignmentsOfferComponent } from '../assignments/assignments-offer/assignments-offer.component';
import { AssignmentsInvoiceComponent } from '../assignments/assignments-invoice/assignments-invoice.component';
import { AssignmentDetailsComponent } from '../assignments/assignment-details/assignment-details.component';
import { TeamsComponent } from '../teams/teams/teams.component';
import { CustomersComponent } from '../customers/customers/customers.component';
import { CoverDetailsComponent } from '../covers/cover-details/cover-details.component';
import { ProfileComponent } from '../profile/profile/profile.component';
import { MaterialsComponent } from '../materials/materials/materials.component';
import { CustomersDetailsComponent } from '../customers/customers-details/customers-details.component';
import { SaveGuardService } from '../../shared/services/save-guard.service';
import { AdminAuthGuardService } from '../../shared/services/admin-auth-guard.service';
import { EmployeesComponent } from '../employees/employees/employees.component';
import { ManholecoversComponent } from '../materials/manholecovers/manholecovers.component';
import { StreetcapsComponent } from '../materials/streetcaps/streetcaps.component';
import { VehicleComponent } from '../vehicles/vehicle/vehicle.component';
import { HydrantcapsComponent } from '../materials/hydrantcaps/hydrantcaps.component';
// import { MainAssignmentComponent } from '../../../modules/assignments/main-assignment/main-assignment.component';
// import { AssignmentsModule } from '../../../modules/assignments/assignments.module';

const routes: Routes = [
  { path: '', redirectTo: '/auftraege/ausstehend', pathMatch: 'full' },
  { path: 'auftraege', redirectTo: '/auftraege/ausstehend', pathMatch: 'full' },
  { path: 'abdeckungen', redirectTo: '/abdeckungen/offen', pathMatch: 'full' },
  { path: 'material', redirectTo: '/material/kanaldeckel', pathMatch: 'full' },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'auftraege',
        component: AssignmentsComponent,
        canActivate: [AdminAuthGuardService],
        children: [
          {
            path: 'ausstehend',
            component: AssignmentsPendingComponent
          },
          {
            path: 'inArbeit',
            component: AssignmentsProgressComponent
          },
          {
            path: 'abgeschlossen',
            component: AssignmentsCompletedComponent
          },
          {
            path: 'rechnung',
            component: AssignmentsInvoiceComponent
          },
          {
            path: 'angebot',
            component: AssignmentsOfferComponent
          },
          {
            path: ':id',
            component: AssignmentDetailsComponent,
            canDeactivate: [SaveGuardService]
          },
        ]
      },
      {
        path: 'abdeckungen',
        component: CoversComponent,
        canActivate: [AdminAuthGuardService],
        children: [
          {
            path: ':id',
            component: CoverDetailsComponent,
            canDeactivate: [SaveGuardService]
          }
        ]
      },
      {
        path: 'teams',
        component: TeamsComponent,
        canActivate: [AdminAuthGuardService],
      },
      {
        path: 'mitarbeiter',
        component: EmployeesComponent,
        canActivate: [AdminAuthGuardService],
      },
      {
        path: 'kunden',
        component: CustomersComponent,
        canActivate: [AdminAuthGuardService],
      },
      {
        path: 'kunden/:id',
        component: CustomersDetailsComponent,
        canActivate: [AdminAuthGuardService],
        canDeactivate: [SaveGuardService]
      },
      {
        path: 'profil',
        component: ProfileComponent,
        canDeactivate: [SaveGuardService]
      },
      {
        path: 'material',
        component: MaterialsComponent,
        canActivate: [AdminAuthGuardService],
        children: [
          {
            path: 'kanaldeckel',
            component: ManholecoversComponent,
          },
          {
            path: 'schieberkappen',
            component: StreetcapsComponent,
          },
          {
            path: 'hydrantenkappen',
            component: HydrantcapsComponent,
          }
        ]
      },
      {
        path: 'fahrzeuge',
        component: VehicleComponent,
        canActivate: [AdminAuthGuardService],
      },
      { path: '**', redirectTo: '/notFound' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
