import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit {
  private adminLinks = [
    {title: "Aufträge", link: "/auftraege/ausstehend"},
    {title: "Teams", link: "/teams"},
    {title: "Fahrzeuge", link: "/fahrzeuge"},
    {title: "Mitarbeiter", link: "/mitarbeiter"},
    {title: "Kunden", link: "/kunden"},
    {title: "Material", link: "/material"},
  ];

  public links;

  constructor(public authService: AuthenticationService) {
    if(this.authService.isAuthenticated) {
        this.links = this.adminLinks;
    }
  }

  ngOnInit() {
    this.authService.jwt_timeout();
  }

  logout() {
    this.authService.logout();
  }

}
