import { Component, OnInit } from '@angular/core';
import { EmployeesApiService } from '../../../shared/services/APIServices/employees-api.service';
import { User } from '../../../shared/classes/user';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {
  public employees: User[] = [];
  private current_employee = null;

  constructor(private employees_service: EmployeesApiService, public auth_service: AuthenticationService, private modal_service: NgbModal) { }

  ngOnInit() {
    this.auth_service.jwt_timeout();
    this.reload_employees();
  }

  reload_employees() {
    this.employees_service.getEmployees().subscribe(
      employees => {
        this.employees = employees;
      },
      error => {
        console.error(error);
      }
    )
  }

  open_create_new_employee_modal(create_new_employee_modal) {
    this.current_employee = new User();
    this.modal_service.open(create_new_employee_modal, { size: 'lg', centered: true });
  }

  create_employee(create_new_employee_modal) {
    this.current_employee.password = 'userDefaultPassword';

    this.employees_service.createEmployee(this.current_employee).subscribe(
      new_vehicle_result => {
        this.current_employee = null;
        create_new_employee_modal.close();
        this.reload_employees();
      },
      error => {
        console.error(error);
      }
    )
  }

  edit_employee(id: number, edit_employee_modal) {
    let temp_employee = this.get_employee_from_array_with_id(id);
    this.current_employee = Object.assign({}, temp_employee); // "clone" object (but not sub-objects)

    let edit_employee_modal_ref = this.modal_service.open(edit_employee_modal, { size: 'lg', centered: true });
    let self_ref = this;
    edit_employee_modal_ref.result.then(function (data) {
      if (data == 'cancel_edit') {
        edit_employee_modal_ref.close();
      }
      else if (data == 'save_edit') {
        self_ref.employees_service.updateEmployeeWithID(id, self_ref.current_employee).subscribe(
          edit_employee_result => {
            edit_employee_modal_ref.close();
            self_ref.reload_employees();
          },
          error => {
            console.error(error);
          }
        )
      }
    })
  }

  delete_employee(id, delete_employee_modal) {
    let delete_employee_modal_ref = this.modal_service.open(delete_employee_modal, { size: 'lg', centered: true });
    let self_ref = this;
    delete_employee_modal_ref.result.then(function (data) {
      if(data == 'cancel_delete_employee') {
        delete_employee_modal_ref.close();
      }
      else if(data == 'delete_employee') {
        self_ref.employees_service.deleteEmployeeWithID(id).subscribe(
          deleted => {
            delete_employee_modal_ref.close();
            self_ref.reload_employees();
          },
          error => {
            console.error(error);
          }
        )
      }
    });  
  }

  get_employee_from_array_with_id(id: number) {
    return this.employees.find(employee => employee.id === id);
  }

}
