import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { CustomersApiService } from '../../../shared/services/APIServices/customers-api.service';
import { Customer } from '../../../shared/classes/customer';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Assignment } from '../../../shared/classes/assignment';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { UserApiService } from '../../../shared/services/APIServices/user-api.service';

@Component({
  selector: 'app-customers-details',
  templateUrl: './customers-details.component.html',
  styleUrls: ['./customers-details.component.scss']
})
export class CustomersDetailsComponent implements OnInit {
  @ViewChild('save_before_leave_modal', null) save_before_leave_modal: ElementRef;

  public customer: Customer;
  public edit_mode: boolean = false;
  private restore_customer: Customer;
  public show_access_to_portal_success = false;
  public show_password_reset_success = false;

  
  
  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  constructor(private orderService: OrdersApiService, private usersService: UserApiService, private router: Router, private customersService: CustomersApiService, private route: ActivatedRoute, private authService: AuthenticationService, private modal_service: NgbModal) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.route.params.subscribe(
      params => {
        var customer_id = +params['id']; // + converts the string to number
        this.customersService.getCustomerWithID(customer_id).subscribe(
          customer => {
            this.customer = customer;
          },
          error => {
            console.error(error);
          }
        )
      });
  }

  edit_customer() {
    this.edit_mode = true;
    this.restore_customer = Customer.copyObject(this.customer);
  }

  save_customer(reload: boolean) {
    this.customersService.updateCustomerWithID(this.customer.id, this.customer).subscribe(
      customer => {
        let user = this.customer.main_contact;
        this.usersService.updateUserWithID(user.id, user).subscribe(
          user => {
            this.customer = customer;
            this.end_edit(reload);
          }
        )
      },
      error => {
        console.error(error);
      }
    )
  }

  reset_password() {
    this.customersService.resetPassword(this.customer.main_contact.id).subscribe(
      customer => {
        this.show_password_reset_success = true;
      },
      error => {
        console.error(error);
      }
    )
  }

  create_password() {
    this.customersService.sendPortalAccess(this.customer.main_contact.id).subscribe(
      customer => {
        this.customer.main_contact.access_to_portal = true;
        this.show_access_to_portal_success = true;
      },
      error => {
        console.error(error);
      }
    )
  }

  end_edit(reload: boolean) {
    this.customer = this.restore_customer;
    this.edit_mode = false;
    if(reload) {
      this.reload_customer();
    }
  }

  reload_customer() {
    this.customersService.getCustomerWithID(this.customer.id).subscribe(
      order => {
        this.customer = order;
      },
      error => {
        console.error(error);
      }
    )
    }

    canDeactivate() {
      if (this.edit_mode) {
        this.modal_service.open(this.save_before_leave_modal, { size: 'lg' });
        return this.navigateAwaySelection$;
      }
      else {
        return true;
      }
  }

  select_leave_options(modal, save_customer: boolean, leave: boolean) {
    if(save_customer) {
      this.save_customer(false);
    }
    modal.close();
    this.navigateAwaySelection$.next(leave);
  }

  
  open_new_order_for_customer_modal(new_order_modal) {
    let new_order_modal_ref = this.modal_service.open(new_order_modal, { size: 'lg', centered: true });
    let self_ref = this;
    new_order_modal_ref.result.then(function (data) {
      if (data == 'cancel_create_order') {
        new_order_modal_ref.close();
      }
      else if (data == 'create_order') {
        self_ref.add_order_for_customer(self_ref.customer.id);
      }
    });
  }

  add_order_for_customer(id: number) {
    var customerOrder = new Assignment();
    var today = new Date().toISOString().slice(0, 10);

    customerOrder.status = 'in_progress';
    customerOrder.company_id = id;
    customerOrder.order_date = today;
    customerOrder.passing_date = today;
    this.orderService.createOrder(customerOrder).subscribe(res => {
        this.reload_customer();
    });
  }


}

