import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { Assignment } from '../../../shared/classes/assignment';


@Component({
  selector: 'app-assignments-invoice',
  templateUrl: './assignments-invoice.component.html',
  styleUrls: ['./assignments-invoice.component.scss']
})
export class AssignmentsInvoiceComponent implements OnInit {
  public assignments: Assignment[];

  constructor(private authService: AuthenticationService, private ordersService: OrdersApiService) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.reloadOrders();
  }

  reloadOrders() {
    this.ordersService.getOrderWithStatus('done_invoice').subscribe(
      orders => {
        this.assignments = orders;
      },
      error => {
        console.error(error);
      }
    )
  }


}
