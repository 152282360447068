import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Assignment } from '../../../shared/classes/assignment';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { COVER_STATUS, ORDER_STATUS, WORK_METHOD, API_ENDPOINT } from '../../../shared/services/const';
import { Cover } from '../../../shared/classes/cover';
import { CoversApiService } from '../../../shared/services/APIServices/covers-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignmentAddCoverComponent } from '../assignment-add-cover/assignment-add-cover.component';
import { TeamsApiService } from '../../../shared/services/APIServices/teams-api.service';
import { Team } from '../../../shared/classes/team';
import { AssignmentsSumlistComponent } from '../assignments-sumlist/assignments-sumlist.component';

@Component({
  selector: 'app-assignment-details',
  templateUrl: './assignment-details.component.html',
  styleUrls: ['./assignment-details.component.scss']
})

export class AssignmentDetailsComponent implements OnInit, OnDestroy {

  public order: Assignment;
  public order_id: number;

  public covers_select_active: boolean = false;
  public selected_covers = [];
  public selected_order;

  public status_to_select = ["in_offer", "passed", "in_progress", "done", "done_invoice"];

  public temp_team;
  public temp_status;

  public work_method = WORK_METHOD;
  public order_status = ORDER_STATUS;

  public fileToUpload: File = null;
  public url_endpoint = API_ENDPOINT;
  public available_teams;
  public new_cover;

  public customer_orders = [];

  constructor(public authService: AuthenticationService, private ordersService: OrdersApiService, private route: ActivatedRoute, private router: Router, private coversService: CoversApiService, private modalService: NgbModal, public teams_service: TeamsApiService) {
  }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.route.params.subscribe(
      params => {
        this.order_id = +params['id']; // + converts the string to number
        this.reloadOrder();
      });
  }

  ngOnDestroy() {

  }

  reloadOrder() {
    this.ordersService.getOrderWithID(this.order_id).subscribe(
      order => {
        this.order = order;
        this.coversService.getCoversForOrderWithID(order.id).subscribe(
          results => {
            this.order.covers = results;
          },
          error => {
            console.error(error);
          }
        )
      },
      error => {
        if (error.status == 404) {
          console.log("404 Error");
          // later redirect to 404?
        }
      }
    )
  }

  change_status(status) {
    let current_time = new Date().toUTCString()

    switch(status) {
      case 'passed':
        this.order.passing_date = current_time;
        break;        
      case 'in_offer':
        this.order.order_date = current_time;
        break;        
      case 'done':
        this.order.done_date = current_time;
        break;
    }
    this.order.status = status;

    this.ordersService.updateOrderWithID(this.order.id, this.order).subscribe(
      order => {
        this.order = order;
        this.reloadOrder();
      },
      error => {
        console.error(error);
      }
    )
  }


  edit_team(edit_team_modal) {
    if(this.order.team){
      this.temp_team = this.order.team;
    }
    else {
      this.temp_team = null;
    }
    this.modalService.open(edit_team_modal, {size: 'lg', centered: true});
    this.teams_service.getTeams().subscribe(
      teams => {
        this.available_teams = teams;
      }, 
      error => {
        console.error(error);
      }
    )
  }

  change_selected_team(team: Team) {
    this.temp_team = team;
  }


  save_team_change(modal) {
    if (this.temp_team) {
      this.order.team = this.temp_team;
      this.order.team_id = this.order.team.id;
      delete this.order['team'];
      this.save_order_changes();
    }
    modal.close();
  }

  open_select_status_modal(modal) {
    if(this.order.status){
      this.temp_status = this.order.status;
    }
    else {
      this.temp_status = null;
    }
    this.modalService.open(modal, {size: 'lg', centered: true});
  }

  change_selected_status(status) {
    this.temp_status = status;
  }

  save_selected_status(modal) {
    if(this.temp_status) {
      this.order.status = this.temp_status;
    }
    modal.close();
    this.save_order_changes();
  }

  open_collection_order_modal() {
    let order_collection_modal_ref = this.modalService.open(AssignmentsSumlistComponent, {size: 'lg', centered: true});
    order_collection_modal_ref.componentInstance.order_id = this.order.id;
    order_collection_modal_ref.componentInstance.customer_id = this.order.company.id;
  }


  create_new_cover(cover?: Cover) {
    let cover_modal_ref = this.modalService.open(AssignmentAddCoverComponent, {size: 'lg', centered: true});
    if(cover) {
      var copied_cover = Cover.copyObject(cover);
      copied_cover.id = null;
      cover_modal_ref.componentInstance.cover_to_copy = copied_cover;
    }    
    cover_modal_ref.componentInstance.order_id = this.order_id;
    cover_modal_ref.componentInstance.company_id = this.order.company.id;
    cover_modal_ref.result.then(() => { 
      this.reloadOrder();
    })
  }

    

  save_order_changes() {
    this.ordersService.updateOrderWithID(this.order.id, this.order).subscribe(
      resultOrder => {
         this.reloadOrder();
     },
     error => {
       console.error(error);
     });
  }

  // add_pdf(type: string, file, id: number) {
  //   this.fileToUpload = file.item(0);
  //   this.coversService.addFile(this.fileToUpload, id, type).subscribe(
  //     result => {
  //       this.reloadOrder();
  //     }
  //   )
  // }

  send_finished_mail() {
    this.coversService.sendFinishedMail(this.order.id).subscribe(
      covers => {
        // TODO show success?? 
        this.reloadOrder();
      },
      error => {
        console.error(error);
      }
    )
  }


updateCheckedCovers(id: string, event) {
  let index = this.selected_covers.indexOf(id);

  if (index > -1) {
    this.selected_covers.splice(index, 1);
  }
  else {
    this.selected_covers.push(id);
  }
}

  send_work_mail() {
    this.coversService.sendWorkMail(this.order.id).subscribe(
      covers => {
        // TODO show success?? 
        this.reloadOrder();
      },
      error => {
        console.error(error);
      }
    )
  }


  delete_pdf(id: number) {
    this.coversService.deleteFile(id).subscribe(
      result => {
        this.reloadOrder();

      }
    )
  }

 change_covers_select_active(active: boolean) {
    this.covers_select_active = active;
  
    if(!this.covers_select_active) {
      this.selected_covers = [];
    }
 }

 open_move_covers_to_order(move_covers_to_order_modal) {
  this.ordersService.getCustomerOrders(this.order.company.id).subscribe(
    orders => {
      this.customer_orders = orders;
    }, 
    error => {
      console.error(error);
    }
  )

  let move_covers_to_order_modal_ref = this.modalService.open(move_covers_to_order_modal, { size: 'lg', centered: true });
  let self_ref = this;
  move_covers_to_order_modal_ref.result.then(function (data) {
      if (data == 'create_new_order') {
        self_ref.new_order_for_customer();
      }  
      else if (data == 'cancel_move_covers') {
        self_ref.reset_move_covers();
        move_covers_to_order_modal_ref.close();
      }
      else if (data == 'selected_order') {
        self_ref.move_covers_to_order();
      }
    });
 }

move_covers_to_order() {
  if(this.selected_order && this.selected_covers.length > 0) {
    this.ordersService.addCoversToOrder(this.selected_order, this.selected_covers).subscribe(
      success => {
        this.reset_move_covers();
        this.reloadOrder();
      },
      error => {
        console.error(error);
      }
    );
  }
}

change_selected_order(id: string) {
  this.selected_order = id;
}

new_order_for_customer() {
  var new_order = new Assignment();
  var today = new Date().toISOString().slice(0, 10);

  new_order.status = 'in_progress';
  new_order.company_id = this.order.company.id;
  new_order.order_date = today;
  new_order.passing_date = today;
  
  this.ordersService.createOrder(new_order).subscribe(
    order => {
      this.coversService.addCoversToOrder(order.id, this.selected_covers).subscribe(
        covers => {
          this.reset_move_covers();
          this.reloadOrder();
        },
        error => {
          console.error(error);
        }
      )},
    error => {
      console.error(error);
    })
  }

  reset_move_covers() {
    this.change_covers_select_active(false);
    this.covers_select_active = false;
    this.selected_order = null;
  }
}