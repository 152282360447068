import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AssignmentsRoutingModule } from './assignments-routing.module';
import { AssignmentsComponent } from './assignments/assignments.component';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { AssignmentDetailsComponent } from './assignment-details/assignment-details.component';
import { AssignmentsPendingComponent } from './assignments-pending/assignments-pending.component';
import { AssignmentsProgressComponent } from './assignments-progress/assignments-progress.component';
import { AssignmentsCompletedComponent } from './assignments-completed/assignments-completed.component';
import { AssignmentsOfferComponent } from './assignments-offer/assignments-offer.component';
import { AssignmentsSumlistComponent } from './assignments-sumlist/assignments-sumlist.component'
import { AssignmentsInvoiceComponent } from './assignments-invoice/assignments-invoice.component'
import { AssignmentAddCoverComponent } from './assignment-add-cover/assignment-add-cover.component';
import { OrdersApiService } from '../../shared/services/APIServices/orders-api.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  imports: [
    CommonModule,
    AssignmentsRoutingModule,
    SharedModule,
    NgbModule
  ],
  declarations: [
    AssignmentsComponent,
    AssignmentDetailsComponent,
    AssignmentsPendingComponent,
    AssignmentsProgressComponent,
    AssignmentsCompletedComponent,
    AssignmentsOfferComponent,
    AssignmentsSumlistComponent,
    AssignmentAddCoverComponent,
    AssignmentsInvoiceComponent
  ],
  providers: [
    OrdersApiService
  ],
  entryComponents: [
    AssignmentAddCoverComponent,
    AssignmentsSumlistComponent
  ]
})
export class AssignmentsModule { }
