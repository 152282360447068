import { Manufacturer } from "./manufacturer";

export class Product {
    public id: string;
    public name: string;
    public manufacturer: Manufacturer;
    public manufacturer_id: string;
    public type: string;
    public work_method: string;
    public manufacturer_name: string;
    
}
