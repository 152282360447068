import { Component, OnInit } from '@angular/core';
import { User } from '../../../shared/classes/user';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { UserApiService } from '../../../shared/services/APIServices/user-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public user;
  public user_to_edit;

  public password_new: string;
  public password_new_second: string;

  constructor(private auth_service: AuthenticationService, private users_service: UserApiService, private modal_service: NgbModal) { }

  ngOnInit() {
    this.auth_service.jwt_timeout();
    this.reload_user();
  }

  reload_user() {
    this.users_service.getUserWithID(this.auth_service.user.user_id).subscribe(
      user => {
        this.user = user;
      },
      error => {
        console.error(error);
      }
    )
  }

  open_edit_user_modal(edit_profile_modal) {
    let temp_user= this.user;
    this.user_to_edit = Object.assign({}, temp_user); // "clone" object (but not sub-objects)

    let edit_user_modal_ref = this.modal_service.open(edit_profile_modal, { size: 'lg', centered: true });
    let self_ref = this;
    edit_user_modal_ref.result.then(function (data) {
      if (data == 'cancel_edit') {
        edit_user_modal_ref.close();
      }
      else if (data == 'save_edit') {
        if(self_ref.password_new != undefined && self_ref.password_new_second != undefined) {
          if(self_ref.password_new == self_ref.password_new_second){
            self_ref.user['password'] = self_ref.password_new;
          }
        }
    
        self_ref.users_service.updateUserWithID(self_ref.user.id, self_ref.user).subscribe(
          edit_user_result => {
            edit_user_modal_ref.close();
            self_ref.reload_user();
          },
          error => {
            console.error(error);
          }
        )
      }
    })
  }
}

