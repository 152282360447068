import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT } from '../const';
import { User } from '../../classes/user';
import { Observable } from 'rxjs';

@Injectable()
export class UserApiService {
  private storageName = 'users';

  constructor(private http: HttpService) { }

  getUserWithID(id: number) {
      return this.http.get(API_ENDPOINT + '/users/' + id).map(
        result => {
          return result;
        }
      );
  }

  updateUserWithID(id: number, user: User) {
      return this.http.put(API_ENDPOINT + '/users/' + id, user).map(
        result => {
          return result;
        }
      );
  }

  create_new_user(data_for_user: any) {
    return this.http.post(API_ENDPOINT + '/users', data_for_user).map(
      result => {
        return result;
      }
    );
  }

}
