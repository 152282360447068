import { Picture } from "./picture";
import { Product } from "./product";
import { Customer } from "./customer";

export class Cover {
    public id: number;
    public status: string;
    public priority: string;
    public gps_lat: number;
    public gps_lng: number;
    public asphalt: string;
    public milling_desired: number;
    public milling_accomplished: number;
    public balancingrings_used: number;
    public cement_used: number;
    public street: string;
    public zip_code: string;
    public city: string;
    public work_method: string;
    public cover_type: string;
    public cover_by_customer: boolean;
    public image: Picture;
    public images_before: Picture[];
    public images_after: Picture[];
    public product: Product;
    public company: Customer;
    public order_id: string;
    public order_status: string;
    public work_information: string;
    public work_information_internal: string;
    public free_manufacturer: string;
    public desired_dusttrap: number;
    public delivered_dusttrap: number;
    public product_id: string;
    public work_date: string;
    public manufacturer_id: number;


    constructor() {

    }

    static copyObject(orginial: Cover): Cover {
        var copiedCover: Cover = new Cover();

        for(let property in orginial) {
            copiedCover[property] = orginial[property];
        }
        return copiedCover;
    }



}
