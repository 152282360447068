export class DateHelper {

    public create_date_from_json_date_and_time(date: object, time: object) {
        let date_string = date["year"] + "-" + date["month"] + "-" + date["day"] + " " + time["hour"] + ":" + time["minute"] + ":" + time["second"];
        return new Date(date_string);
    }

    public create_date_from_json(date: object) {
        let date_string = date["year"] + "-" + date["month"] + "-" + date["day"];
        return new Date(date_string);
    }

    //  TODO TIMEZONE KRAM

    public create_jsons_from_date(date_string: string) {
        let date = new Date(date_string);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        return {
            year: year,
            month: month,
            day: day
        }
    }

    public create_jsons_from_time(time_string) {
        let date = new Date(time_string);

        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();

        return {
            hour: hour,
            minute: minute,
            second: second
        }
    }
}
