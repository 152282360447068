import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleComponent } from './vehicle/vehicle.component';
import { SharedModule } from '../../shared/modules/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
  ],
  declarations: [VehicleComponent],
})
export class VehiclesModule { }
