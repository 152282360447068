import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT, SYNC_STATUS } from '../const';
import { Assignment } from '../../classes/assignment';
import { Cover } from '../../classes/cover';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersApiService {

  constructor(private http: HttpService) { }

  getOrderWithStatus(status?: string) {
      return this.http.get(API_ENDPOINT + '/orders?status=' + status ).map(
        results => {
          return results;
        },
      );
  }

  getOrderWithID(id: number) {
      return this.http.get(API_ENDPOINT + '/orders/' + id).map(
        result => {
          return result;
        }
      );
  }

  updateOrderWithID(id: number, order: Assignment) {
      return this.http.put(API_ENDPOINT + '/orders/' + id, order).map(
        result => {
          return result;
        }
      );
  }

  deleteOrderWithID(id: number) {
      return this.http.delete(API_ENDPOINT + '/orders/' + id).map(
        response => {
          return response;
        },
      );
  }

  createOrder(order: Assignment) {
      return this.http.post(API_ENDPOINT + '/orders', order).map(
        result => {
          return result;
        }
      );
  }

  getCustomerOrders(id:number){
      return this.http.get(API_ENDPOINT + '/customerorders/' + id).map(
        response => {
          return response;
        },
      );
  }

  createOrderList(id:number, orders){
      return this.http.post(API_ENDPOINT + '/massorder/' + id, {"sendids": orders}).map(
        result => {
          return result;
        }
      );
  }

  getOrderWithStatusForTeam(team_id: number, status: string) {
      return this.http.get(API_ENDPOINT + '/teams/' + team_id + '/orders', { status: status }).map(
        results => {
          return results;
        }
      );
  }

  get_work_list(order_id: number) {
    // # lists?type=work, invoice_intern, invoice_customer   
    return this.http.getDownload(API_ENDPOINT + '/orders/' + order_id + '/lists?type=work', 'application/pdf');  
}

addCoversToOrder(order_id: number, ids: number[]) {
  let params = {
    'ids[]': ids
  }
  return this.http.putMultiple(API_ENDPOINT + '/covers', { order_id: order_id }, params).map(
    results => {
      return results;
    }
  );
}



}
