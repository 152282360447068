import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { API_ENDPOINT } from '../const';
import { Team } from '../../classes/team';
import { Observable } from 'rxjs';
import { Employees } from '../../classes/employees';

@Injectable({
  providedIn: 'root'
})
export class TeamsApiService {
  private storageName = 'teams';


  constructor(private http: HttpService) { }

  getTeams() {
    return this.http.get(API_ENDPOINT + '/teams').map(
      results => {
        return results;
      },
    );
  }

  getTeamWithID(id: number) {
    return this.http.get(API_ENDPOINT + '/teams/' + id).map(
      result => {
        return result;
      }
    );
  }

  createTeam(team: Team) {
    return this.http.post(API_ENDPOINT + '/teams', team).map(
      team => {
        return team;
      },
    );
  }

  updateTeamWithID(id: number, team: Team) {
    return this.http.put(API_ENDPOINT + '/teams/' + id, team).map(
      team => {
        return team;
      }
    );
  }

  deleteUsersfromTeam(id: number) {
    let data = { "id": id };
    return this.http.put(API_ENDPOINT + '/removeuserfromteam/', data).subscribe(
      employee => {
        return employee;
      },
      error => {
        console.error(error)
      }
    );
  }

  deleteTeamWithID(id: number) {
    return this.http.delete(API_ENDPOINT + '/teams/' + id).map(
      response => {
        return response;
      },
    );
  }
}
