import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { NavigationService } from '../../../shared/services/navigation.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public connected_current = true;
  public connected_old = true;

  constructor(private authService: AuthenticationService, private navigation_service: NavigationService) {
    this.authService.jwt_timeout();
  }


  ngOnInit() {
  }
}
