import { Component, OnInit } from '@angular/core';
import { TeamsApiService } from '../../../shared/services/APIServices/teams-api.service';
import { Team } from '../../../shared/classes/team';
import { EmployeesApiService } from '../../../shared/services/APIServices/employees-api.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehiclesApiService } from '../../../shared/services/APIServices/vehicles-api.service';
import { error } from 'protractor';


@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  public teams: Team[] = [];
  public selectable_employees = [];
  public selectable_vehicles = [];
  public selected_employees = [];
  private current_team: Team;
  private selected_vehicle;

  constructor(private teams_service: TeamsApiService, private vehicle_service: VehiclesApiService, private employees_service: EmployeesApiService, private auth_service: AuthenticationService, private modal_service: NgbModal) {

  }

  ngOnInit() {
    this.auth_service.jwt_timeout();
    this.reload_teams();
  }

  reload_teams() {
    this.teams_service.getTeams().subscribe(
      teams => {
        this.teams = teams;
      },
      error => {
        console.error(error);
      }
    )
  }

  change_selected_employees(id) {
    if (this.selected_employees.includes(id)) {
      let index = this.selected_employees.indexOf(id);
      this.selected_employees.slice(index);
    }
    else {
      this.selected_employees.push(id);
    }
  }

  change_selected_vehicle(vehicle) {
    this.selected_vehicle = vehicle;
  }

  open_create_new_team_modal(create_new_team_modal) {
    this.current_team = new Team();
    this.selected_employees = [];
    this.selected_vehicle = null;
    this.load_team_components();
    this.modal_service.open(create_new_team_modal, { size: 'lg', centered: true });
  }

  open_delete_team_modal(team_id, delete_team_modal) {
    this.modal_service.open(delete_team_modal, { size: 'lg', centered: true });
  }

  load_team_components() {
    this.vehicle_service.getVehicles().subscribe(
      vehicles => {
        this.selectable_vehicles = vehicles;
      },
      error => {
        console.error(error);
      }
    )

    this.employees_service.getEmployees().subscribe(
      employees => {
        this.selectable_employees = employees;
      },
      error => {
        console.error(error);
      })
  }

  create_team(create_new_team_modal) {
    this.current_team.vehicle_id = this.selected_vehicle.id;
    this.teams_service.createTeam(this.current_team).subscribe(
      new_team_result => {
        this.employees_service.updateMultipleEmployeesWithTeamID(new_team_result.id, this.selected_employees).subscribe(
          update_employees_result => {
            this.selected_employees = [];
            this.selected_vehicle = null;
            this.current_team = null;
            create_new_team_modal.close();
            this.reload_teams();
          },
          error => {
            console.error(error);
          }
        )
      },
      error => {
        console.error(error);
      }
    )
  }

  edit_team(id: number, edit_team_modal) {
    this.load_team_components();
    let temp_team = this.get_team_from_array_with_id(id);
    this.current_team = Object.assign({}, temp_team);

    this.selected_vehicle = this.current_team.vehicle.id
    this.selected_vehicle = this.current_team.vehicle;
    this.selected_employees = [];
    for (let employee of this.current_team.employees) {
      this.selected_employees.push(employee.id);
    }

    let team_edit_modal_ref = this.modal_service.open(edit_team_modal, { size: 'lg', centered: true });
    let self_ref = this;
    team_edit_modal_ref.result.then(function (data) {
      if (data == 'cancel_edit') {
        team_edit_modal_ref.close();
      }
      else if (data == 'save_edit') {
        var ids = self_ref.selected_employees;
        delete self_ref.current_team['employees'];
        delete self_ref.current_team['employee_ids'];
        delete self_ref.current_team['vehicle'];
        let teammember_difference = temp_team.employees.length - ids.length

        if (teammember_difference > 0) {
          let missing = self_ref.current_team.employee_ids.filter(item => ids.indexOf(item) < 0);
          for (let i = 0; i < missing.length; i++) {
            self_ref.teams_service.deleteUsersfromTeam(missing[i])
            self_ref.reload_teams();
          }
        }

        self_ref.teams_service.updateTeamWithID(id, self_ref.current_team).subscribe(
          teamResult => {
            if (ids.length != 0) {
              self_ref.employees_service.updateMultipleEmployeesWithTeamID(teamResult.id, ids).subscribe(
                employeesResult => {
                  team_edit_modal_ref.close();
                  self_ref.reload_teams();
                },
                error => {
                  console.error(error);
                }
              )
            }
          },
          error => {
            console.error(error);
          }
        )
      }
    })
  }

  
  delete_team(id: number, delete_team_modal) {
    let delete_team_modal_ref = this.modal_service.open(delete_team_modal, { size: 'lg', centered: true });
    let self_ref = this;
    delete_team_modal_ref.result.then(function (data) {
      if(data == 'cancel_delete_team') {
        delete_team_modal_ref.close();
      }
      else if(data == 'delete_team') {
        self_ref.teams_service.deleteTeamWithID(id).subscribe(
          deleted => {
            delete_team_modal_ref.close();
            self_ref.reload_teams();
          },
          error => {
            console.error(error);
          }
        )
      }
    });
    }

  get_team_from_array_with_id(id: number) {
      return this.teams.find(team => team.id === id);
    }

}
