import { Component, OnInit } from '@angular/core';
// import { DeleteGuardComponent } from '../../../shared/components/delete-guard/delete-guard.component';
import { ProductsApiService } from '../../../shared/services/APIServices/products-api.service';
import { Router, NavigationEnd } from '@angular/router';
import { Product } from '../../../shared/classes/product';
import { Manufacturer } from '../../../shared/classes/manufacturer';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent implements OnInit {
  public links = [
    { title: "Kanaldeckel", link: "/material/kanaldeckel" },
    { title: "Schieberkappen", link: "/material/schieberkappen" },
    { title: "Hydrantenkappen", link: "/material/hydrantenkappen" }
  ];

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.authService.jwt_timeout();
  }

}
