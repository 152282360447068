import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { Assignment } from '../../../shared/classes/assignment';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent implements OnInit {

  // basic routes for all user types/roles
  public links = [
    { title: "Von Kunden", link: "/auftraege/ausstehend" },
    { title: "In Arbeit", link: "/auftraege/inArbeit" },
    { title: "Abgeschlossen", link: "/auftraege/abgeschlossen" },
    { title: "Rechnung geschrieben", link: "/auftraege/rechnung" },
    { title: "Angebotsanfragen", link: "/auftraege/angebot" }
  ];
  public show_sidenav: boolean = true;
  private sidenav_urls = ["/auftraege/ausstehend", "/auftraege/inArbeit", "/auftraege/abgeschlossen", "/auftraege/angebot", "/auftraege/vorgemerkt", "/auftraege/rechnung"];

  constructor(public authService: AuthenticationService, private router: Router, private ordersService: OrdersApiService) {
    this.check_url(this.router.url);
  }


  check_url(url: string) {
    if (this.sidenav_urls.includes(url)) {
      this.show_sidenav = true;
    }
    else {
      this.show_sidenav = false;
    }
  }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.router.events.subscribe((events) => {
      if (events instanceof NavigationStart) {
        this.check_url(events.url);
      }
    });

  }
}
