import { Product } from "../classes/product";

export class CoverProductsHelper {

    public fillManufacturers(products: Product[], work_method?: string) {
        var manufacturers = [];
        for (let product of products) {
            if (work_method) {
                if ((work_method == 'smart_repair' || work_method == 'asphalt_only') && !this.includesManufacturer(manufacturers, product.manufacturer.id)) {
                    manufacturers.push(product.manufacturer);
                }
                else if (work_method == product.work_method && !this.includesManufacturer(manufacturers, product.manufacturer.id)) {
                    manufacturers.push(product.manufacturer);
                }
            }
            else {
                if (!this.includesManufacturer(manufacturers, product.manufacturer.id)) {
                    manufacturers.push(product.manufacturer);
                }
            }
        }
        return manufacturers;
    }

    includesManufacturer(manufacturers: any[], currentManufacturer_id: string) {
        for (let manufacturer of manufacturers) {
            if (manufacturer.id == currentManufacturer_id) {
                return true;
            }
        }
        return false;
    }


    public fillSelectableProducts(products: Product[], manufacturer_id: string, work_method?: string) {
        var productsToSelect = [];
        for (let product of products) {
            if (product.manufacturer.id == manufacturer_id) {
                if (work_method) {
                    if (work_method == 'smart_repair' || work_method == 'asphalt_only') {
                        productsToSelect.push(product);
                    }
                    else if (work_method == product.work_method) {
                        productsToSelect.push(product);
                    }
                }
                else {
                    productsToSelect.push(product);
                }
            }
        }
        return productsToSelect;
    }
}