import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { OrdersApiService } from '../../../shared/services/APIServices/orders-api.service';
import { Assignment } from '../../../shared/classes/assignment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assignments-progress',
  templateUrl: './assignments-progress.component.html',
  styleUrls: ['./assignments-progress.component.scss']
})
export class AssignmentsProgressComponent implements OnInit {
  public assignments: Assignment[];

  constructor(private authService: AuthenticationService, private ordersService: OrdersApiService) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.reloadOrders();
  }

  reloadOrders() {
      this.ordersService.getOrderWithStatus('in_progress').subscribe(
        orders => {
          this.assignments = orders;
        },
        error => {
          console.error(error);
        }
      )
  }
}

